var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-content"},[_c('div',{staticClass:"step-content__aside"},[_c('transition',{attrs:{"name":"fade"}},[_c('OrderSummaryWithTopBar')],1)],1),_vm._v(" "),_c('div',{staticClass:"step-content__main"},[_c('VaimoHeading',{staticClass:"sf-heading--left sf-heading--no-underline title",attrs:{"heading-level":"h2","heading":_vm.$t('Your payment method')}}),_vm._v(" "),_c('div',{staticClass:"summary"},[_c('div',{class:{ loaded: !_vm.isShippingMethodLoaded && !_vm.additionalLoading }},[_c('SfLoader',{attrs:{"loading":_vm.isShippingMethodLoaded || _vm.additionalLoading}})],1),_vm._v(" "),_c('div',{class:_vm.terms ? 'summary__group terms-selected' : 'summary__group'},[(!_vm.isJpStore)?_c('AdyenPaymentProvider',{directives:[{name:"e2e",rawName:"v-e2e",value:('adyen-payment-provider'),expression:"'adyen-payment-provider'"}],attrs:{"before-pay":_vm.onAdyenBeforePay,"after-pay":_vm.onAdyenAfterPay,"on-error":_vm.onAdyenErrorCaptured,"show-pay-button":true,"is-authenticated":_vm.isAuthenticated},on:{"on-3ds-loaded":_vm.loaderHandler},scopedSlots:_vm._u([{key:"pay-button",fn:function(ref){
var submitPayment = ref.submitPayment;
return [_c('div',{staticClass:"summary__action"},[_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('make-an-order'),expression:"'make-an-order'"}],staticClass:"summary__action-button vaimo-button__primary",attrs:{"disabled":!_vm.terms,"aria-label":_vm.$t('Make an order'),"tabindex":"0"},on:{"click":submitPayment,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return submitPayment.apply(null, arguments)}}},[_vm._v("\n                "+_vm._s(_vm.$t('Make an order'))+"\n              ")])],1)]}}],null,false,1243268418)}):_vm._e(),_vm._v(" "),(_vm.isJpStore)?[_c('NpPaymentProvider',{on:{"status":function($event){_vm.isPaymentReady = true}}}),_vm._v(" "),_c('GmoPaymentProvider',{ref:"GmoPaymentProviderRef",attrs:{"before-pay":_vm.onGmoBeforePay,"after-pay":_vm.onGmoAfterPay,"on-error":_vm.onGmoError},on:{"status":function($event){_vm.isPaymentReady = true}}})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"place-order__wrapper"},[_c('div',{staticClass:"place-order-container"},[(_vm.loading)?_c('SfLoader'):_vm._e(),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('place-order'),expression:"'place-order'"}],staticClass:"summary__action-button vaimo-button__primary",attrs:{"disabled":!_vm.terms || (!_vm.isPaymentReady && _vm.isJpStore),"aria-label":_vm.$t('Place Order'),"tabindex":"0"},on:{"click":_vm.placeOrder,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.placeOrder.apply(null, arguments)}}},[_vm._v("\n              "+_vm._s(_vm.$t('Place Order'))+"\n            ")])],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"terms","rules":{ required: { allowFalse: false } },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isJpStore)?_c('div',{staticClass:"sf-checkbox__label"},[_vm._v("\n              "+_vm._s(_vm.$t('I agree to'))+"\n              "),_c('a',{staticClass:"underline",attrs:{"href":_vm.getTermsOfServiceLinks.terms_link,"target":"_blank","aria-label":_vm.$t(
                    _vm.isDeStore
                      ? 'Terms and conditions'
                      : 'General Terms and Conditions'
                  ) +
                  ' ' +
                  _vm.$t('Opens in new tab'),"rel":"noreferrer nofollow"}},[_vm._v(_vm._s(_vm.$t(
                    _vm.isDeStore
                      ? 'Terms and conditions'
                      : 'General Terms and Conditions'
                  )))]),_vm._v(" "),(_vm.isDeStore)?_c('span',[_vm._v("\n                "+_vm._s(_vm.$t('understand'))+"\n              ")]):_vm._e()]):_c('div',{staticClass:"jp-terms-policy"},[_c('a',{staticClass:"underline",attrs:{"href":_vm.getTermsOfServiceLinks.terms_link,"target":"_blank","rel":"noreferrer nofollow"}},[_vm._v("\n                "+_vm._s(_vm.$t('Terms of Use'))+"\n              ")]),_vm._v("\n              "+_vm._s(_vm.$t('I agree with'))+"\n            ")]),_vm._v(" "),_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('terms'),expression:"'terms'"}],staticClass:"summary__terms",attrs:{"name":"terms","label":_vm.$t('Terms and conditions'),"selected":_vm.terms,"valid":!errors[0],"error-message":_vm.$t(errors[0]),"model-value":true}})]}}])})],1)],2)]),_vm._v(" "),_c('EditEmail'),_vm._v(" "),_c('EditAddress'),_vm._v(" "),(_vm.areAddressesDifferent)?_c('EditAddress',{attrs:{"address-type":"billing"}}):_vm._e(),_vm._v(" "),_c('EditShipping'),_vm._v(" "),_c('LoyaltyPushRenderer',{staticClass:"loyalty-push__full-width",attrs:{"render-area":"Checkout - Payment methods step"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }