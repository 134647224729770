<template>
  <div
    v-if="isVisible"
    class="vaimo-banner"
    :class="{ 'hover-on-state': hoverOnState }"
  >
    <transition name="fade">
      <RatioWrapper class="vaimo-banner__aspect-wrapper" :ratio="aspectRatio">
        <template v-if="isImage">
          <VaimoSpyWrapper
            :tag="imageLinkTag"
            :link="normalizeLink(link)"
            :aria-label="title || $t('Learn more about our products')"
            @contextmenu.prevent
            @click.native="getClickPictureDetails"
          >
            <picture
              class="vaimo-banner__picture"
              @[mayZoom]="onPictureClicked"
              @mousedown="onPictureMouseDown"
              @mouseenter="onHoverEnter"
              @mouseleave="onHoverLeave"
              @touchstart="onTouchStart"
              @touchend="onTouchEnd"
              @contextmenu="onContextMenu"
            >
              <source
                v-for="srce in imageSrcset.sources"
                :key="srce.id"
                :type="srce.type"
                :media="srce.media"
                :srcset="`${srce.src}, ${srce.src2x}`"
              />
              <nuxt-img
                v-if="lcp"
                ref="imageRef"
                :style="parallaxStyles"
                preload
                fetchpriority="high"
                :src="imageSrcset.main.src"
                :srcset="imageSrcset.main.src2x"
                :alt="title || $t('Banner image')"
                class="vaimo-banner__image"
                :width="imageSrcset.main.width"
                :height="imageSrcset.main.height"
                :loading="loading"
                :aria-label="title || $t('Banner image')"
              />
              <nuxt-img
                v-else
                ref="imageRef"
                :style="parallaxStyles"
                :src="imageSrcset.main.src"
                :srcset="imageSrcset.main.src2x"
                :alt="title || $t('Banner image')"
                class="vaimo-banner__image"
                :width="imageSrcset.main.width"
                :height="imageSrcset.main.height"
                :loading="loading"
                :preload="loading === 'eager'"
                :aria-label="title || $t('Banner image')"
              />
            </picture>
            <slot name="engravingInfoPleatLite" />
            <picture
              v-if="imageSrcset.hover"
              class="vaimo-banner__picture vaimo-banner__hover-picture"
              @mouseenter="onHoverEnter"
              @mouseleave="onHoverLeave"
              @touchstart="onTouchStart"
              @touchend="onTouchEnd"
              @contextmenu="onContextMenu"
            >
              <source
                v-for="srce in imageSrcset.hoverSources"
                :key="srce.id"
                :type="srce.type"
                :media="srce.media"
                :srcset="`${srce.src}, ${srce.src2x}`"
              />
              <nuxt-img
                ref="imageRef"
                :style="parallaxStyles"
                :src="imageSrcset.hover.src"
                :srcset="imageSrcset.hover.src2x"
                :alt="title || $t('Banner image')"
                class="vaimo-banner__image"
                :width="imageSrcset.hover.width"
                :height="imageSrcset.hover.height"
                loading="lazy"
                :preload="loading === 'eager'"
                :aria-label="title || $t('Banner image')"
              />
            </picture>
            <div v-if="labels && labels.length" class="vaimo-banner__labels">
              <VaimoBadge
                v-for="label in labels"
                :key="label"
                class="vaimo-banner__label mr-xs"
              >
                {{ label }}
              </VaimoBadge>
            </div>
          </VaimoSpyWrapper>
        </template>
        <template v-else-if="isVideo">
          <video
            ref="video"
            v-bind="videoSettings"
            playsinline="playsinline"
            webkit-playsinline
            :aria-label="title || $t('Video Banner')"
            class="cursor-pointer vaimo-banner__video"
            @click="videoControls.toggleVideoPlayback"
          >
            <source :src="srcAdaptive" type="video/mp4" />
          </video>
          <span
            class="vaimo-banner__video-control"
            tabindex="0"
            :class="getControlsClass()"
            @keypress.enter="onPressPlay"
          >
            <span v-if="videoPaused" @click="videoControls.play()">
              <VaimoIcon
                :icon="getColoredIcon('play')"
                :size="12"
                :label="$t('Play')"
              />
            </span>
            <span v-else @click="videoControls.pause()">
              <VaimoIcon
                :icon="getColoredIcon('pause')"
                :size="12"
                :label="$t('Pause')"
              />
            </span>
          </span>
          <span
            class="vaimo-banner__video-control control-right"
            tabindex="0"
            :class="getControlsClass()"
            @keypress.enter="onPressMute"
          >
            <span v-if="!videoMuted" @click="videoControls.mute()">
              <VaimoIcon
                :icon="getColoredIcon('unmute')"
                :size="12"
                :label="$t('Mute')"
              />
            </span>
            <span v-else @click="videoControls.unmute()">
              <VaimoIcon
                :icon="getColoredIcon('mute')"
                :size="12"
                :label="$t('Unmute')"
              />
            </span>
          </span>
        </template>
      </RatioWrapper>
    </transition>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  ref,
  toRef,
  useContext,
  watch
} from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import VaimoIcon from 'atoms/VaimoIcon.vue';
import { throttle } from 'lodash-es';
import Vue from 'vue';

import { useUiState } from '~/composables/useUiState';
import { useLink, useScreenSize } from '~/diptyqueTheme/composable';
import { getAssetSource } from '~/diptyqueTheme/helpers/assetSources';

export default defineComponent({
  name: 'VaimoBanner',
  components: {
    RatioWrapper: () => import('templates/RatioWrapper.vue'),
    VaimoSpyWrapper: () => import('atoms/helper/VaimoSpyWrapper.vue'),
    VaimoBadge: () => import('atoms/VaimoBadge.vue'),
    SfLink
  },
  props: {
    parallax: {
      type: Boolean
    },
    ratio: {
      type: String,
      required: false,
      default: '3:4'
    },
    labels: {
      type: Array,
      required: false,
      default: () => []
    },
    src: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'image'
    },
    srcDesktop: {
      type: String,
      required: false,
      default: ''
    },
    srcHover: {
      type: [String, null],
      required: false,
      default: () => null
    },
    srcHoverDesktop: {
      type: [String, null],
      required: false,
      default: () => null
    },
    srcAdaptive: {
      type: String,
      required: false,
      default: ''
    },
    typeDesktop: {
      type: String,
      required: false,
      default: ''
    },
    link: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true
    },
    muted: {
      type: Boolean,
      required: false,
      default: true
    },
    loop: {
      type: Boolean,
      required: false,
      default: true
    },
    // Source of the visual which affects the way of optimization
    source: {
      type: String,
      required: false,
      default: ''
    },
    maxOptimizedWidth: {
      type: Number,
      required: false,
      default: 1920
    },
    quality: {
      type: Number,
      required: false,
      default: 90
    },
    zooming: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: String,
      required: false,
      default: 'lazy'
    },
    preload: {
      type: Boolean,
      required: false,
      default: false
    },
    controlsColor: {
      type: String,
      required: false,
      default: 'black'
    },
    // Page name, where the banner is rendered
    pageName: {
      type: String,
      required: false,
      default: ''
    },
    // Section name, which renders the banner
    sectionName: {
      type: String,
      required: false,
      default: ''
    },
    lcp: {
      type: Boolean,
      required: false,
      default: false
    },
    visible: {
      type: [Boolean, null],
      required: false,
      default: null
    },
    zoomOnCenter: {
      type: Boolean,
      required: false,
      default: false
    },
    zoomPercent: {
      type: Number,
      required: false,
      default: 100
    },
    showMobileButton: {
      type: Boolean,
      required: false,
      default: false
    },
    typeName: {
      type: String,
      required: false,
      default: ''
    },
    convertToSvg: Boolean
  },
  setup(props) {
    const ctx = useContext();
    const { app } = useContext();
    const { normalizeLink } = useLink();
    const { $gtm, i18n } = app;
    const { isIos } = app.$device;
    const { openProductZoom, closeProductZoom } = useUiState();
    const { isMobile, isDesktop } = useScreenSize();
    const video = ref(null);

    const aspectRatio = computed(() => props.ratio.replace(':', '-'));
    const contentType = computed(() =>
      isDesktop.value && props.typeDesktop ? props.typeDesktop : props.type
    );
    const isImage = computed(() => contentType.value === 'image');
    const isVideo = computed(() => contentType.value === 'video');
    const imageLinkTag = computed(() =>
      props.link && props.link !== '#' && props.typeName !== 'EcomPush'
        ? 'SfLink'
        : null
    );
    const zoomPercent = toRef(props, 'zoomPercent');
    const videoMuted = ref(props.muted);
    const videoLoop = ref(props.loop);
    const videoPaused = ref(!props.autoplay);

    const videoSettings = computed(() => {
      const settings = {};
      settings.muted = videoMuted.value ? true : null;
      settings.loop = videoLoop.value ? true : null;
      settings.playsinline = true;
      return settings;
    });

    const videoControls = {
      play: () => {
        video.value?.play();
        videoPaused.value = false;
      },
      pause: () => {
        video.value?.pause();
        videoPaused.value = true;
      },
      mute: () => {
        videoMuted.value = true;
      },
      unmute: () => {
        videoMuted.value = null;
      },
      toggleVideoPlayback: () => {
        videoPaused.value ? videoControls.play() : videoControls.pause();
      }
    };

    const onPressPlay = () => {
      if (videoPaused.value) {
        return videoControls.play();
      }
      if (!videoPaused.value) {
        return videoControls.pause();
      }
    };

    const onPressMute = () => {
      if (videoMuted.value === null) {
        return videoControls.mute();
      }
      if (videoMuted.value === true) {
        return videoControls.unmute();
      }
    };

    const iosVersion = computed(() => {
      if (!ctx.$device.isIos) return false;
      const userAgent = ctx.$device.userAgent;
      const match = userAgent.match(/iPhone OS (\d+)/);
      const version = match ? match[1] : false;
      return version;
    });

    const safariVersion = computed(() => {
      if (!ctx.$device.isSafari) return false;
      const userAgent = ctx.$device.userAgent;
      const safariIndex = userAgent.indexOf('Safari');
      const versionIndex = userAgent.indexOf('Version/');
      const version = parseFloat(
        userAgent.substring(versionIndex + 8, safariIndex - 1)
      );
      return version;
    });

    const isOld = computed(() => {
      const safari = safariVersion.value;
      const ios = iosVersion.value;
      return (ios && ios < 16) || (safari && safari < 16);
    });

    const buildContentfulSourceObject = (src, screenMaxWidth, imageWidth) => {
      if (!src) return null;
      if (!imageWidth) imageWidth = screenMaxWidth;
      if (imageWidth > props.maxOptimizedWidth)
        imageWidth = props.maxOptimizedWidth;

      const imageFileWidth = imageWidth;
      const imageFileWidth_retina2x = imageFileWidth * 2;

      let format = src.match(/\.([a-zA-Z0-9]+)(?:[\?\#]|$)/)[1];
      if (props.convertToSvg) {
        format = 'svg';
      }
      if (!iosVersion.value || iosVersion.value > 13) {
        format = isOld.value || format === 'gif' ? 'webp' : 'avif';
      }

      const imageSrc = props.convertToSvg
        ? `${src}?&w=${imageFileWidth}&q=${100}`
        : `${src}?fm=${format}&w=${imageFileWidth}&q=${props.quality}`;
      const imageSrc2x = props.convertToSvg
        ? `${src}?&w=${imageFileWidth_retina2x}&q=${100} 2x`
        : `${src}?fm=${format}&w=${imageFileWidth_retina2x}&q=${props.quality} 2x`;

      const id = screenMaxWidth;
      const ratioArr = props.ratio.split(':');
      const imageHeight = Math.round((imageWidth / ratioArr[0]) * ratioArr[1]);

      return {
        type: `image/${format}`,
        media: `(max-width: ${screenMaxWidth}px)`,
        src: imageSrc,
        src2x: imageSrc2x,
        width: imageWidth,
        height: imageHeight,
        id: id
      };
    };

    const buildMagentoSourceObject = (src, screenMaxWidth, imageWidth) => {
      if (!src) return null;
      if (!imageWidth) imageWidth = screenMaxWidth;
      if (imageWidth > props.maxOptimizedWidth)
        imageWidth = props.maxOptimizedWidth;

      const imageFileWidth = imageWidth;
      const imageFileWidth_retina2x = imageFileWidth * 2;

      let format = 'webp';
      const formatParam =
        !iosVersion.value || iosVersion.value > 13 ? 'format=' + format : '';

      const id = screenMaxWidth;
      const ratioArr = props.ratio.split(':');
      const imageHeight = Math.round((imageWidth / ratioArr[0]) * ratioArr[1]);
      const concatSymbol = src.indexOf('?') >= 0 ? '&' : '?';

      return {
        type: `image/${format}`,
        media: `(max-width: ${screenMaxWidth}px)`,
        src: `${src}${concatSymbol}${formatParam}&width=${imageFileWidth}&quality=${props.quality}`,
        src2x: `${src}${concatSymbol}${formatParam}&width=${imageFileWidth_retina2x}&quality=${props.quality} 2x`,
        width: imageWidth,
        height: imageHeight,
        id: id
      };
    };

    const buildSourceObject = (src, screenMaxWidth, imageWidth) => {
      const assetSource = getAssetSource(src);
      if (assetSource === 'contentful') {
        return buildContentfulSourceObject(src, screenMaxWidth, imageWidth);
      } else if (assetSource === 'magento') {
        return buildMagentoSourceObject(src, screenMaxWidth, imageWidth);
      }
      return null;
    };

    const buildSourcesSet = (
      set,
      srcMobile,
      srcDesktop,
      sizeMap,
      type = 'main'
    ) => {
      if (!srcMobile) return;
      const max = props.maxOptimizedWidth;
      const mobileDesktopFringe = 1024;

      if (type === 'main') {
        set.main = buildSourceObject(srcDesktop, max);
      } else if (type === 'hover') {
        set.hover = buildSourceObject(srcDesktop, max);
      }

      sizeMap.forEach((size) => {
        const screenMaxWidth = Array.isArray(size) ? size[0] : size;
        const imageWidth = Array.isArray(size) ? size[1] : size;
        const srcLink =
          screenMaxWidth < mobileDesktopFringe ? srcMobile : srcDesktop;
        const sourceObject = buildSourceObject(
          srcLink,
          screenMaxWidth,
          imageWidth
        );
        const sourcesType = type === 'hover' ? 'hoverSources' : 'sources';
        if (sourceObject) set[sourcesType].push(sourceObject);
      });
    };

    const applySetPlaceholder = (set) => {
      set.placeholder =
        'data:image/svg+xml,%3Csvg%20xmlns=\'http://www.w3.org/2000/svg\'%20viewBox=\'0%200%20' +
        set.main.width +
        '%20' +
        set.main.height +
        "'%3E%3C/svg%3E";
    };

    const imageSrcset = computed(() => {
      const set = { sources: [], main: null, hover: null, hoverSources: [] };
      const srcMobile = props.src;
      const srcHoverMobile = props.srcHover;
      const srcDesktop = props.srcDesktop || srcMobile;
      const srcHoverDesktop = props.srcHoverDesktop || srcHoverMobile;

      if (!srcMobile && !srcDesktop) return set;

      const sizeMap = [440, 768, [1023, 1024], 1360, 1600];
      buildSourcesSet(set, srcMobile, srcDesktop, sizeMap, 'main');
      buildSourcesSet(set, srcHoverMobile, srcHoverDesktop, sizeMap, 'hover');
      applySetPlaceholder(set);

      return set;
    });

    let mouseDownScreenX;
    const onPictureMouseDown = (e) => {
      mouseDownScreenX = e.screenX;
    };
    const onPictureClicked = (e) => {
      if (props.zooming && e.screenX === mouseDownScreenX) zoom(e);
    };

    const zoom = (e) => {
      const rect = e.target.getBoundingClientRect();
      const $picture = e.target.closest('.vaimo-banner');
      const $pictureClone = $picture.cloneNode(true);
      $pictureClone.classList.remove(...$pictureClone.classList);
      $pictureClone.classList.add('vaimo-banner');

      let xPercent = (e.clientX - rect.left) / rect.width;
      let yPercent = (e.clientY - rect.top) / rect.height;

      if (props.zoomOnCenter) {
        xPercent = 0.5;
        yPercent = 0.5;
      }

      const $wrapper = document.createElement('div');
      $wrapper.classList.add('vaimo-banner__zoom-wrapper');

      if (props.showMobileButton) {
        $wrapper.classList.add('button-visible');
      }

      const $close = document.createElement('div');
      $close.classList.add('vaimo-banner__zoom-wrapper-close');

      const VaimoIconClass = Vue.extend(VaimoIcon);
      const closeIcon = new VaimoIconClass({
        propsData: {
          icon: 'cross',
          size: 24,
          label: i18n.t('Close')
        }
      });
      closeIcon.$mount();
      $close.appendChild(closeIcon.$el);

      $wrapper.appendChild($pictureClone);
      $wrapper.appendChild($close);
      document.body.appendChild($wrapper);

      if (zoomPercent.value !== 100) {
        const zoomingPicture = $wrapper.querySelector(
          'picture.vaimo-banner__picture'
        );
        zoomingPicture.style.width = zoomPercent.value + '%';
        zoomingPicture.style.height = zoomPercent.value + '%';
      }

      $wrapper.scrollLeft =
        ($wrapper.scrollWidth - $wrapper.clientWidth) * xPercent;
      $wrapper.scrollTop =
        ($wrapper.scrollHeight - $wrapper.clientHeight) * yPercent;
      $wrapper.classList.add('fadeIn');

      const onCloseClicked = (e) => {
        e.target.closest('.vaimo-banner__zoom-wrapper').remove();
        closeProductZoom();
      };

      $close.addEventListener('click', onCloseClicked);
      openProductZoom();
    };

    const getControlsClass = () => {
      return 'text-' + props.controlsColor;
    };

    const hoverOnState = ref(false);
    let touchTimer;

    const getClickPictureDetails = () => {
      if (props?.pageName === 'Home' && imageLinkTag.value === 'SfLink') {
        $gtm.push({
          event: 'clickHPPictures',
          sectionName: props.sectionName
        });
      }
    };

    const getColoredIcon = (icon) => {
      const color =
        props.controlsColor === 'black' ? '' : '_' + props.controlsColor;
      return icon + color;
    };

    const isVisible = computed(() => {
      if (props.visible !== null) return props.visible;
      return props.src;
    });

    const onContextMenu = ($event) => {
      /** Disable the context menu on mobile devices. **/
      if (isMobile?.value) {
        $event.preventDefault();
      }
    };

    const handleIntersection = ([video]) => {
      if (video.isIntersecting) {
        videoControls.play();
      } else {
        videoControls.pause();

        if (!videoMuted.value) videoControls.mute();
      }
    };

    let observer, unwatch;

    if (props.autoplay) {
      unwatch = watch(video, (videoRef) => {
        observer = new IntersectionObserver(handleIntersection, {
          root: null,
          rootMargin: '-100px 0px -50px 0px',
          threshold: 0
        });

        observer.observe(videoRef);
      });
    }

    props.autoplay &&
      onBeforeUnmount(() => {
        if (observer) {
          observer.disconnect();
        }

        if (unwatch) {
          unwatch();
        }
      });

    const imageVerticalPosition = ref(0);
    const imageRef = ref(null);
    let viewportHeight;
    let imageObserver;
    const ONE_HUNDRED = 100;

    const setImageVerticalPosition = () => {
      const rect = imageRef.value.$el.getBoundingClientRect();

      imageVerticalPosition.value = Math.max(
        0,
        Math.min(
          ONE_HUNDRED,
          ((viewportHeight - rect.top) / (viewportHeight + rect.height)) *
            ONE_HUNDRED
        )
      );
    };

    const setViewportHeight = () => {
      viewportHeight = window.innerHeight;
    };

    const parallaxStyles = computed(
      () =>
        props.parallax && `object-position: 50% ${imageVerticalPosition.value}%`
    );

    const THROTTLE_TIME = 50;
    const throttledSetImageVerticalPosition = throttle(
      setImageVerticalPosition,
      THROTTLE_TIME
    );

    const createIntersectionObserver = () => {
      const handleIntersection = ([entry]) => {
        if (entry.isIntersecting) {
          window.addEventListener('scroll', throttledSetImageVerticalPosition, {
            passive: true
          });
          window.addEventListener('resize', onResize, { passive: true });
        } else {
          window.removeEventListener(
            'scroll',
            throttledSetImageVerticalPosition
          );
          window.removeEventListener('resize', onResize);
        }
      };

      imageObserver = new IntersectionObserver(handleIntersection, {
        root: null
      });

      imageObserver.observe(imageRef.value.$el);
    };

    const onResize = () => {
      setViewportHeight();
      setImageVerticalPosition();
    };

    const onImageLoaded = () => {
      setViewportHeight();
      createIntersectionObserver();
    };

    watch(imageRef, () => {
      if (props.parallax) {
        setViewportHeight();
        setImageVerticalPosition();
        imageRef.value?.$el.addEventListener('load', onImageLoaded);
      }
    });

    onBeforeUnmount(() => {
      if (imageRef.value?.$el && imageObserver) {
        imageObserver.unobserve(imageRef.value.$el);
        imageRef.value.$el.removeEventListener('load', onImageLoaded);
      }

      window.removeEventListener('scroll', throttledSetImageVerticalPosition);

      window.removeEventListener('resize', onResize);
    });

    return {
      parallaxStyles,
      imageRef,
      isVisible,
      onContextMenu,
      normalizeLink,
      aspectRatio,
      isImage,
      isVideo,
      imageLinkTag,
      videoPaused,
      videoControls,
      videoMuted,
      videoSettings,
      imageSrcset,
      mayZoom: computed(() => (props.zooming && !props.link ? 'click' : null)),
      onPictureClicked,
      onPictureMouseDown,
      getControlsClass,
      hoverOnState,
      onHoverEnter: () => {
        if (isIos) return;
        nextTick(() => {
          hoverOnState.value = !!props.srcHover;
        });
      },
      onHoverLeave: () => {
        if (isIos) return;
        nextTick(() => {
          hoverOnState.value = false;
        });
      },
      onTouchStart: () => {
        touchTimer = setTimeout(() => {
          hoverOnState.value = !!props.srcHover;
        }, 500);
      },
      onTouchEnd: () => {
        nextTick(() => {
          clearTimeout(touchTimer);
          hoverOnState.value = false;
        });
      },
      getClickPictureDetails,
      getColoredIcon,
      onPressPlay,
      onPressMute,
      i18n,
      video
    };
  }
});
</script>

<style lang="scss" scoped>
.vaimo-banner {
  &__image {
    color: transparent;
    transition: object-position 0.05s linear;
  }
  &__video {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }
  &__aspect-wrapper {
    position: relative;

    a {
      -webkit-touch-callout: none;
      user-select: none;
    }
  }
  &__video-control {
    display: block;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    &.control-right {
      left: auto;
      right: 0px;
    }
  }
  &__labels {
    display: flex;
    position: absolute;
    align-items: baseline;
    top: 5px;
    left: 5px;
    width: inherit;
  }
  &__hover-picture {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.75s;
  }
  &.hover-on-state {
    .vaimo-banner__hover-picture {
      opacity: 1;
    }
  }

  svg {
    fill: none;
    path {
      fill: #fff;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.vaimo-banner__zoom-wrapper {
  position: fixed;
  z-index: 501;
  left: 0px;
  top: 0px;
  background: #fff;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: auto;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  overscroll-behavior: contain;

  &.button-visible {
    @include for-screen-s {
      height: calc(100dvh - 58px);
    }
  }

  &.fadeIn {
    opacity: 1;
  }

  > .vaimo-banner {
    width: 100%;
    @include for-screen-s {
      transform-origin: 0 0;
    }
  }

  &-close {
    position: fixed;
    right: 20px;
    top: 20px;
    background-color: $blanc;
    padding: 8px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>
