import { render, staticRenderFns } from "./VaimoBadge.vue?vue&type=template&id=1bd66d94&scoped=true&"
import script from "./VaimoBadge.vue?vue&type=script&lang=js&"
export * from "./VaimoBadge.vue?vue&type=script&lang=js&"
import style0 from "./VaimoBadge.vue?vue&type=style&index=0&id=1bd66d94&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd66d94",
  null
  
)

export default component.exports