













































































































































































































































































































































import { SfInput, SfButton } from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
  useFetch,
  onMounted,
  Ref,
  readonly,
  reactive,
  watch,
  nextTick
} from '@nuxtjs/composition-api';
import { required, min } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { useGuestUser, useConfig } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';
import {
  customerPasswordRegExp,
  invalidPasswordMsg
} from '~/modules/customer/helpers/passwordValidation';
import isEmailAvailable from '~/diptyqueTheme/customQueries/magento/isEmailAvailable.gql';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';
import { emailRegex } from '~/diptyqueTheme/helpers/regexes';
import LoyaltyPushRenderer from '~/diptyqueTheme/components/templates/sections/LoyaltyPush/LoyaltyPushRenderer.vue';

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters'
});
extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});

extend('password', {
  message: invalidPasswordMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPasswordRegExp.test(value)
});

export default defineComponent({
  name: 'UserAccount',
  components: {
    SfInput,
    SfButton,
    ValidationProvider,
    ValidationObserver,
    LoyaltyPushRenderer,
    VaimoLoader: () =>
      import('~/diptyqueTheme/components/atoms/VaimoLoader.vue'),
    VaimoCheckbox: () => import('molecules/VaimoCheckbox.vue'),
    VaimoSocialLogin: () => import('organisms/VaimoSocialLogin.vue'),
    VaimoPasswordInput: () => import('organisms/VaimoPasswordInput.vue'),
    AccountForgotPasswordForm: () =>
      import('organisms/account/parts/AccountForgotPasswordForm.vue')
  },
  scrollToTop: true,
  emits: ['onForgotPassword'],
  setup(_, { emit }) {
    const { config } = useConfig();
    const router = useRouter();
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $recaptcha, $config, i18n } = useContext();
    const { getBeginCheckoutDetails, EVENT_TIMEOUT } = useGoogleTagManager();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha
    );
    const storeCode = i18n.localeProperties.code;
    const isStoreDe = storeCode === 'de_eu';
    const {
      attachToCart,
      loading: loadingGuestUser,
      error: errorGuestUser
    } = useGuestUser();

    const { cart, load: loadCart } = useCart();

    const {
      load,
      loading: loadingUser,
      register,
      login,
      user,
      isAuthenticated,
      error: errorUser
    } = useUser();

    const getTermsOfServiceLinks = computed(() => {
      return termsOfServiceLinks[i18n.locale];
    });

    const isFormSubmitted = ref(false);
    const createUserAccount = ref(false);
    const loginUserAccount = ref(false);
    const loading = computed(() => loadingUser.value || loadingGuestUser.value);

    const canMoveForward = computed(() => !loading.value);
    const userErrors = computed(
      () => errorUser.value.login || errorUser.value.register
    );
    const guestErrors = computed(() => errorGuestUser.value.attachToCart);

    const queryLoading: Ref<boolean> = ref(false);
    const isLoading: Ref<boolean> = ref(false);
    const isEmailEnabled = ref(false);
    const updateError = ref(null);
    const confirm_password_type = ref('password');
    const new_password_type = ref('password');
    const continueToShipping = ref(null);
    const isAGuest = ref(false);

    type Form = {
      firstname: string;
      lastname: string;
      email: string;
      password: string;
      mobile: string;
      is_subscribed: boolean;
      recaptchaToken?: string;
      recaptchaInstance?: string;
      confirmedPassword: string;
      remember_me: boolean;
      optin_mail: boolean;
      optin_sms: boolean;
      optin_newsletter: boolean;
      activateNlValidation: boolean;
      newsletterConfirmation: boolean;
    };

    const form = reactive<Form>({
      firstname: ' ',
      lastname: ' ',
      email: '',
      password: '',
      mobile: '0000000000',
      is_subscribed: false,
      confirmedPassword: '',
      remember_me: false,
      optin_mail: false,
      optin_sms: false,
      optin_newsletter: false,
      activateNlValidation: false,
      newsletterConfirmation: false
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isCheckedByDefault = computed(() => config.value?.is_subscribed);
    const isSocialLoginEnabled = computed(
      () => config.value?.sociallogin_general_enabled
    );

    if (isCheckedByDefault.value) {
      form.optin_mail = !!isCheckedByDefault.value;
      form.optin_sms = !!isCheckedByDefault.value;
      form.optin_newsletter = !!isCheckedByDefault.value;
    }

    watch(isCheckedByDefault, (newVal) => {
      form.optin_mail = !!newVal;
      form.optin_sms = !!newVal;
      form.optin_newsletter = !!newVal;
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const optins = computed(() => config.value?.optins);
    const optinNewsletter = ref(null);
    const optinSms = ref(null);
    const optinMail = ref(null);

    if (optins.value) {
      optinNewsletter.value = optins.value?.includes('optin_newsletter');
      optinSms.value = optins.value?.includes('optin_sms');
      optinMail.value = optins.value?.includes('optin_mail');
    }

    watch(optins, () => {
      optinNewsletter.value = optins.value?.includes('optin_newsletter');
      optinSms.value = optins.value?.includes('optin_sms');
      optinMail.value = optins.value?.includes('optin_mail');
    });

    const selectType = (type) => {
      if (type === 'optin_mail') {
        form.optin_mail = !form.optin_mail;
      } else if (type === 'optin_sms') {
        form.optin_sms = !form.optin_sms;
      } else if (type === 'optin_newsletter') {
        form.optin_newsletter = !form.optin_newsletter;
        form['activateNlValidation'] = isStoreDe && form['optin_newsletter'];
      } else if (type === 'remember_me') {
        form.remember_me = !form.remember_me;
      }
    };

    const getNewsletterConfirmation = (newsletterConfirmation) => {
      form[newsletterConfirmation] = !form[newsletterConfirmation];
    };

    const newsletterValidation = computed(() => {
      if (!form['optin_newsletter'] || !form['activateNlValidation']) {
        return true;
      }
      return form['newsletterConfirmation'];
    });

    watch(
      () => isAuthenticated.value,
      () => {
        form.email = user.value.email;
      }
    );

    const validatePassword = computed(() => {
      if (form.password !== form.confirmedPassword) {
        updateError.value = i18n.t('Please enter the same value again.');
        return true;
      }
      updateError.value = '';
      return false;
    });

    const checkPasswordFields = computed(() => {
      return !!(form.password && form.confirmedPassword);
    });

    const handleFormSubmit = (reset: () => void) => async () => {
      isLoading.value = true;

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
        isLoading.value = false;
      }

      if (!isAuthenticated.value) {
        if (isRecaptchaEnabled.value && createUserAccount.value) {
          const recaptchaToken = await $recaptcha.getResponse();
          form.recaptchaToken = recaptchaToken;
          form.recaptchaInstance = $recaptcha;
        }

        await (createUserAccount.value
          ? register({ user: form })
          : attachToCart({ email: form.email, cart }));
      }

      if (loginUserAccount.value) {
        const recaptchaParams: { recaptchaToken?: string } = {};
        if (isRecaptchaEnabled.value) {
          recaptchaParams.recaptchaToken = await $recaptcha.getResponse();
        }

        await login({
          user: {
            email: form.email,
            password: form.password,
            ...recaptchaParams
          }
        });
      }

      if (
        (isAGuest.value && !guestErrors.value) ||
        (!isAGuest.value && !userErrors.value)
      ) {
        form.password = '';
        form.confirmedPassword = '';
        await mergeItem('checkout', { 'user-account': form });
        await router.push(app.localeRoute({ name: 'shipping-address' }));
        reset();
        isFormSubmitted.value = true;
      } else {
        isLoading.value = false;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.reset();
      }
    };

    const checkIsCustomerRegistered = async () => {
      try {
        queryLoading.value = true;
        const res = await app.$vsf.$magento.api.customQuery({
          query: isEmailAvailable,
          queryVariables: {
            email: form.email
          }
        });

        isEmailEnabled.value =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          res.data?.isEmailAvailable?.is_email_available ?? false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (res.data?.isEmailAvailable?.is_email_available) {
          createUserAccount.value = true;
          loginUserAccount.value = false;
        } else {
          createUserAccount.value = false;
          loginUserAccount.value = true;
        }

        window.scroll(0, 0);
      } catch (e) {
        console.log(e);
      } finally {
        queryLoading.value = false;
      }
    };

    const resetStep = () => {
      createUserAccount.value = false;
      loginUserAccount.value = false;
    };

    const emailInputHandler = (input) => {
      if (input !== form.email) {
        form.email = input;
        resetStep();
      }
    };

    const continueAsGuest = () => {
      resetStep();
      isAGuest.value = true;
      window.scroll(0, 0);
      return true;
    };

    const changeInputType = (item) => {
      if (item === 'confirm_password') {
        confirm_password_type.value =
          confirm_password_type.value === 'text' ? 'password' : 'text';
      } else if (item === 'new_password') {
        new_password_type.value =
          new_password_type.value === 'text' ? 'password' : 'text';
      }
    };

    const onForgotPassword = () => {
      isForgotPasswordStep.value = !isForgotPasswordStep.value;
    };
    const isForgotPasswordStep = ref(false);
    const changeStep = () => {
      isForgotPasswordStep.value = !isForgotPasswordStep.value;
    };

    useFetch(async () => {
      if (user.value === null) {
        await load();
      }
      if (isAuthenticated.value) {
        form.firstname = user.value.firstname;
        form.lastname = user.value.lastname;
        form.email = user.value.email;
      }
    });

    onMounted(async () => {
      const mainLayout = document.getElementById('layout');
      if (mainLayout) mainLayout.style.minHeight = '100%';

      nextTick(() => {
        window.scroll(0, 0);
      });

      const checkout = await getItem('checkout');
      if (checkout && checkout['user-account']) {
        const data = checkout['user-account'];
        form.email = data.email;
        form.firstname = data.firstname;
        form.lastname = data.lastname;
      }

      if (isAuthenticated.value && canMoveForward.value) {
        continueToShipping.value?.children?.[0] &&
          continueToShipping.value.children[0].click();
      } else {
        watch(canMoveForward, () => {
          continueToShipping.value?.children?.[0] &&
            continueToShipping.value.children[0].click();
        });
      }

      if (!cart.value.id) {
        await loadCart();
      }

      setTimeout(() => {
        app.$gtm.push(getBeginCheckoutDetails(cart.value));
      }, EVENT_TIMEOUT);
    });

    return {
      canMoveForward,
      createUserAccount,
      errorUser,
      form,
      handleFormSubmit,
      isAuthenticated,
      isFormSubmitted,
      loading,
      loginUserAccount,
      user,
      isRecaptchaEnabled,
      checkIsCustomerRegistered,
      queryLoading: readonly(computed(() => queryLoading.value)),
      isEmailEnabled,
      resetStep,
      emailInputHandler,
      checkPasswordFields,
      validatePassword,
      updateError,
      confirm_password_type,
      new_password_type,
      changeInputType,
      continueAsGuest,
      isLoading,
      continueToShipping,
      isAGuest,
      isCheckedByDefault,
      optinNewsletter,
      optinSms,
      optinMail,
      selectType,
      onForgotPassword,
      isForgotPasswordStep,
      changeStep,
      getNewsletterConfirmation,
      newsletterValidation,
      getTermsOfServiceLinks,
      isStoreDe,
      isSocialLoginEnabled
    };
  }
});
