




















































































































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  useContext,
  useFetch,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { SfButton, SfCheckbox, SfLoader } from '@storefront-ui/vue';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import { useApi, useUiNotification } from '~/composables';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import { lsGet, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';
import isValidJpDeliveryDate from '~/diptyqueTheme/customQueries/magento/isValidJpDeliveryDate.gql';
import { useSelectedPaymentMethodStore } from '~/diptyqueTheme/stores/selectedPaymentMethod';
import { useSelectedShippingMethodStore } from '~/diptyqueTheme/stores/selectedShippingMethod';
import { removeItem } from '~/helpers/asyncLocalStorage';
import useCart from '~/modules/checkout/composables/useCart';
import useMakeOrder from '~/modules/checkout/composables/useMakeOrder';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useUser } from '~/modules/customer/composables/useUser';
import LoyaltyPushRenderer from '~/diptyqueTheme/components/templates/sections/LoyaltyPush/LoyaltyPushRenderer.vue';

import type {
  JPDeliveryDateResponse,
  JPDeliveryDateVariables
} from '../../types';
import { debounce } from 'lodash-es';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default defineComponent({
  name: 'ReviewOrderAndPayment',
  components: {
    SfCheckbox,
    SfLoader,
    VaimoHeading: () =>
      import('~/diptyqueTheme/components/atoms/VaimoHeading.vue'),
    SfButton,
    ValidationProvider,
    LoyaltyPushRenderer,
    AdyenPaymentProvider: () =>
      import('~/integrations/adyen/components/AdyenPaymentProvider.vue'),
    OrderSummaryWithTopBar: () =>
      import(
        '~/diptyqueTheme/modules/checkout/components/OrderSummaryWithTopBar.vue'
      ),
    EditEmail: () =>
      import('~/diptyqueTheme/modules/checkout/components/EditEmail.vue'),
    EditAddress: () =>
      import('~/diptyqueTheme/modules/checkout/components/EditAddress.vue'),
    EditShipping: () =>
      import('~/diptyqueTheme/modules/checkout/components/EditShipping.vue'),
    NpPaymentProvider: () =>
      import(
        '~/diptyqueTheme/modules/checkout/components/NpPaymentProvider.vue'
      ),
    GmoPaymentProvider: () =>
      import(
        '~/diptyqueTheme/modules/gmo-payment/components/GmoPaymentProvider.vue'
      )
  },
  scrollToTop: true,
  setup() {
    const { isAuthenticated } = useUser();
    const { cart, load, setCart } = useCart();
    const { getAddPaymentInfoDetails } = useGoogleTagManager();
    const { app } = useContext();
    const router = useRouter();
    const terms = ref(true);
    const { send: sendNotification } = useUiNotification();
    const shipping = ref('');
    const billing = ref('');
    const isDeStore = computed(() => {
      const storeCode = app.i18n.localeProperties.code;
      return storeCode === 'de_eu';
    });
    const areAddressesDifferent = computed(() => {
      return JSON.stringify(shipping.value) !== JSON.stringify(billing.value);
    });
    const propertiesToRemoveBeforeComparing = [
      'customerAddressId',
      'sameAsShipping'
    ];
    const selectedShippingMethodStore = useSelectedShippingMethodStore();
    const isShippingMethodLoaded = ref(true);
    const loading = ref(false);
    const additionalLoading = ref(false);
    // eslint-disable-next-line eqeqeq
    const isJpStore = computed(() => app.i18n.localeProperties.code == 'ja_jp');
    const isPaymentReady = ref(false);
    const order = ref(null);
    const { make: makeOrder, error: makeOrderError } = useMakeOrder();
    const GmoPaymentProviderRef = ref(null);
    const selectedPaymentMethodStore = useSelectedPaymentMethodStore();
    const { query } = useApi();

    useFetch(async () => {
      if (!cart.value.id) {
        await load();
      }
    });

    const getTermsOfServiceLinks = computed(() => {
      return termsOfServiceLinks[app.i18n.locale];
    });

    watch(
      () => selectedShippingMethodStore.isSelectionInProgress,
      (isSelectionInProgress) => {
        if (!isSelectionInProgress) {
          isShippingMethodLoaded.value = false;
        }
      }
    );

    onMounted(async () => {
      isShippingMethodLoaded.value = true;
      if (!selectedShippingMethodStore.isSelectionInProgress) {
        isShippingMethodLoaded.value = false;
      }
      const vsfCheckoutData = lsGet('vsf-checkout');
      for (const propToRemove of propertiesToRemoveBeforeComparing) {
        delete vsfCheckoutData?.['billing']?.['billingDetails']?.[propToRemove];
      }
      shipping.value = JSON.stringify(vsfCheckoutData['shipping-address']);
      billing.value = JSON.stringify(
        vsfCheckoutData['billing']?.['billingDetails']
      );

      if (selectedPaymentMethodStore.selectedPaymentMethodCode) {
        isPaymentReady.value = true;
      }
    });

    const getSuccessRoute = (orderNumber) => {
      return app.localeRoute({
        name: 'thank-you',
        query: {
          order: orderNumber
        }
      });
    };

    const makeOrderHandler = async () => {
      loading.value = true;
      additionalLoading.value = true;
      order.value = await makeOrder();

      if (!order.value?.order?.order_number && makeOrderError.value?.make) {
        const message =
          makeOrderError.value?.make?.message ||
          app.i18n.t(
            'The payment was refused. Please try a different payment method or card.'
          );

        sendNotification({
          id: Symbol('use_make_order_error'),
          message: `${message}`,
          persist: false,
          type: 'danger',
          icon: 'cross'
        });

        loading.value = false;
        additionalLoading.value = false;

        return;
      }

      lsSet('store_cart', {});
      app.$vsf.$magento.config.state.removeCartId();
      setCart(null);
      await removeItem('checkout');
      const thankYouRoute = getSuccessRoute(order.value.order.order_number);
      loading.value = false;
      additionalLoading.value = false;
      await router.push(thankYouRoute);
      selectedShippingMethodStore.selectedShippingMethodData = null;
      selectedPaymentMethodStore.setPaymentMethod(null);
    };

    const placeOrder = async () => {
      if (cart.value?.vw_delivery_date_info?.delivery_date && isJpStore.value) {
        loading.value = true;
        additionalLoading.value = true;

        const { data } = await query<
          JPDeliveryDateResponse,
          JPDeliveryDateVariables
        >(isValidJpDeliveryDate, {
          cart_id: cart.value.id
        });
        loading.value = false;
        additionalLoading.value = false;
        if (!data?.isValidJpDeliveryDate?.is_valid_jp_delivery_date) {
          await router.push(`${app.localePath(`/checkout/shipping`)}`);
          let message = app.i18n.t(
            'Your last selected delivery date in the cart is outdated. Please check it again.'
          ) as string;
          sendNotification({
            id: Symbol('set_click_and_collect_shop_error'),
            message: message,
            type: 'danger',
            persist: false,
            title: app.i18n.t('Something went wrong!') as string
          });
          return;
        }
      }

      const selectedMethod = document.querySelector(
        '.adyen-checkout__payment-method--selected'
      );

      if (selectedMethod) {
        const selectedMethodPayButton = selectedMethod.querySelector(
          '.adyen-checkout__button'
        );
        const paypalButtonIfSelected = selectedMethod.querySelector(
          '.adyen-checkout__paypal__buttons'
        );
        const appleButtonIfSelected = selectedMethod.querySelector(
          '.adyen-checkout__applepay__button'
        );

        if (paypalButtonIfSelected) {
          simulateClick(paypalButtonIfSelected);
        } else if (appleButtonIfSelected) {
          simulateClick(appleButtonIfSelected);
        } else {
          simulateClick(selectedMethodPayButton);
        }
      } else if (
        selectedPaymentMethodStore.selectedPaymentMethodCode.startsWith(
          'np_gmo_payment_credit_card'
        )
      ) {
        await GmoPaymentProviderRef.value.triggerGmoPayment();
      } else {
        await makeOrderHandler();
      }
    };

    const simulateClick = function (elem) {
      // Create our event (with options)
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      });
      // If cancelled, don't dispatch our event
      const canceled = !elem.dispatchEvent(evt);
    };

    const onAdyenBeforePay = (stateData) => {
      app.$gtm.push(getAddPaymentInfoDetails(cart.value));
      loading.value = true;
      additionalLoading.value = true;

      // todo: @i.sprut refactor
      if (isAuthenticated.value) {
        const isSavedCart =
          stateData.paymentMethod?.brand &&
          !('storePaymentMethod' in stateData);

        if (isSavedCart || stateData.storePaymentMethod) {
          stateData.recurringProcessingModel = 'CardOnFile';
          stateData.storePaymentMethod = true;
        }
      }

      return terms.value ? stateData : false;
    };

    const onAdyenAfterPay = async ({ order }) => {
      lsSet('store_cart', {});
      app.$vsf.$magento.config.state.removeCartId();
      setCart(null);
      await removeItem('checkout');
      const thankYouRoute = getSuccessRoute(order.id);
      loading.value = false;
      additionalLoading.value = false;
      await router.push(thankYouRoute);
      selectedShippingMethodStore.selectedShippingMethodData = null;
    };

    const onAdyenErrorCaptured = async ({ error }) => {
      loading.value = false;
      additionalLoading.value = false;

      const message =
        // eslint-disable-next-line no-magic-numbers
        error?.status === 403
          ? app.i18n.t('Configuration issues')
          : error?.message ||
            app.i18n.t(
              'The payment was refused. Please try a different payment method or card.'
            );

      sendNotification({
        id: Symbol('adyen_dropin_error'),
        message:
          message === 'Unknown error'
            ? (app.i18n.t('The payment is REFUSED') as string)
            : `${message}`,
        persist: false,
        type: 'danger',
        icon: 'cross'
      });
    };

    const onGmoBeforePay = () => {
      loading.value = true;
      additionalLoading.value = true;
    };

    const onGmoAfterPay = async ({ order }) => {
      lsSet('store_cart', {});
      app.$vsf.$magento.config.state.removeCartId();
      setCart(null);
      await removeItem('checkout');
      const thankYouRoute = getSuccessRoute(order.order_number);
      loading.value = false;
      additionalLoading.value = false;
      await router.push(thankYouRoute);
      selectedShippingMethodStore.selectedShippingMethodData = null;
    };

    const onGmoError = async (error) => {
      loading.value = false;
      additionalLoading.value = false;
      sendNotification({
        id: Symbol('gmo_credit_card_error'),
        message: error,
        persist: false,
        type: 'danger',
        icon: 'cross'
      });
    };

    const loaderHandler = () => {
      additionalLoading.value = false;
    };

    let observer: MutationObserver | null = null;

    const getElementsInfo = (): {
      paddingLeft: number;
      paddingRight: number;
      paddingBottom: number;
      marginBottom: number;
      sfCheckboxHeight: number;
    } | null => {
      const placeOrderWrapper = document.querySelector(
        '.place-order__wrapper'
      ) as HTMLElement | null;
      if (!placeOrderWrapper) return null;
      const placeOrderContainer = placeOrderWrapper.querySelector(
        '.place-order-container'
      ) as HTMLElement | null;
      const sfCheckbox = placeOrderWrapper.querySelector(
        '.sf-checkbox__label'
      ) as HTMLElement | null;

      const paddingRight = parseFloat(
        getComputedStyle(placeOrderWrapper).paddingRight
      );
      const paddingBottom = parseFloat(
        getComputedStyle(placeOrderWrapper).paddingBottom
      );
      const paddingLeft = parseFloat(
        getComputedStyle(placeOrderWrapper).paddingLeft
      );
      const marginBottom = placeOrderContainer
        ? parseFloat(getComputedStyle(placeOrderContainer).marginBottom)
        : 0;
      const sfCheckboxHeight = sfCheckbox ? sfCheckbox.offsetHeight : 0;
      return {
        paddingRight,
        paddingBottom,
        paddingLeft,
        marginBottom,
        sfCheckboxHeight
      };
    };

    const updatePayPalButtonStyles = () => {
      const paypalButton = document.querySelector(
        '.paypal-buttons'
      ) as HTMLElement | null;
      const elementsInfo = getElementsInfo();

      if (paypalButton && elementsInfo) {
        const {
          paddingRight,
          paddingBottom,
          paddingLeft,
          marginBottom,
          sfCheckboxHeight
        } = elementsInfo;
        paypalButton.style.left = `${paddingLeft}px`;
        paddingLeft > 0 || paddingRight > 0
          ? (paypalButton.style.width = `calc(100% - ${
              paddingLeft + paddingRight
            }px)`)
          : (paypalButton.style.width = '100%');
        paypalButton.style.bottom = `${
          paddingBottom + marginBottom + sfCheckboxHeight
        }px`;
      }
    };

    const initObserver = () => {
      observer = new MutationObserver((mutationsList) => {
        if (mutationsList.some((mutation) => mutation.addedNodes.length)) {
          if (document.querySelector('.paypal-buttons')) {
            observer.disconnect();
            updatePayPalButtonStyles();
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    };

    const RESIZE_DEBOUNCE_DELAY = 200;
    const onResize = debounce(updatePayPalButtonStyles, RESIZE_DEBOUNCE_DELAY);

    onMounted(() => {
      initObserver();
      window.addEventListener('resize', onResize);
    });

    onBeforeUnmount(() => {
      if (observer) observer.disconnect();
      window.removeEventListener('resize', onResize);
    });

    return {
      cart,
      cartGetters,
      isAuthenticated,
      terms,
      onAdyenBeforePay,
      onAdyenAfterPay,
      onAdyenErrorCaptured,
      areAddressesDifferent,
      getTermsOfServiceLinks,
      isDeStore,
      placeOrder,
      isShippingMethodLoaded,
      loading,
      additionalLoading,
      isJpStore,
      isPaymentReady,
      GmoPaymentProviderRef,
      onGmoBeforePay,
      onGmoAfterPay,
      onGmoError,
      loaderHandler
    };
  }
});
